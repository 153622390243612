import "uno.css";
import "virtual:unocss-devtools";
//
import ReactDOM from "react-dom/client";
import EntryClient from "./entry-client";
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<EntryClient />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
